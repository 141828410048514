import {useEffect, useState} from 'react';
import {Cell, Button, Toast, Loading} from 'zarm';
import axios from "axios";
import 'zarm/dist/zarm.css';
import Tab from "./tab";

const QuestionList = () => {
    const [questionList, setQuestionList] = useState<any[]>([]);
    useEffect(() => {
        document.title = "问题列表"
        const token = localStorage.getItem("token");
        if (token === "" || token === null) {
            return;
        }
        Loading.show();
        axios.get("/api/getQuestionList").then(function (res) {
            console.log(res);
            if (res.data.code === -1) {
                Toast.show(res.data.msg);
            } else {
                setQuestionList(res.data.data)
            }
        }).catch(function (error) {
            Toast.show("加载失败：" + error);
        }).then(function () {
            Loading.hide();
        });
    }, [])


    return (
        <div>
            <Button theme="primary" style={{display: "block", margin: "auto"}} href="/#/questionAdd">提交问题</Button>
            {questionList.length > 0 && questionList.map((value, index) => <Cell key={index}
                                                                                 title={<div className="box">
                                                                                     <div className="box-title" style={{color:"red"}}>{"问："+value.question}
                                                                                     </div>
                                                                                     <div
                                                                                         className="box-description">{"答："+value.answer}
                                                                                     </div>
                                                                                 </div>}/>)}
            <Tab data="questionList"/>
        </div>
    );
};

export default QuestionList;