import {useEffect, useState} from 'react';
import {Cell, Toast, Loading, Panel, Tabs, Button} from 'zarm';
import axios from "axios";
import 'zarm/dist/zarm.css';
import Tab from "./tab";

const Home = () => {
    const [rankList, setRankList] = useState<any[]>([]);

    useEffect(() => {
        document.title = "排行榜"
        const token = localStorage.getItem("token");
        if (token === "" || token === null) {
            return;
        }
        Loading.show();
        axios.get("/api/getRank").then(function (res) {
            console.log(res);
            if (res.data.code === -1) {
                Toast.show(res.data.msg);
            } else {
                setRankList(res.data.data)
            }
        }).catch(function (error) {
            Toast.show("加载失败：" + error);
        }).then(function () {
            Loading.hide();
        });
    }, []);

    return (
        <div>
            <div>
                <Button theme="primary" style={{display: "block", margin: "auto"}} href="/#/add">提交新数据</Button>
                <Tabs
                    swipeable
                    onChange={(i) => {
                        console.log(i);
                    }}
                >
                    <Panel title="总浏览量">
                        <Cell title="姓名">{"浏览量"}</Cell>
                        {rankList.length > 0 && rankList[0].map((value, index) => <Cell key={index}
                                                                                        title={value.name}>{"" + value.total}</Cell>)}
                        <Cell title="总和">{rankList.length > 0 && rankList[0].reduce((a, b) => a + b.total, 0)+""}</Cell>
                    </Panel>
                    <Panel title="本年总量">
                        <Cell title="姓名">{"浏览量"}</Cell>
                        {rankList.length > 0 && rankList[1].map((value, index) => <Cell key={index}
                                                                                        title={value.name}>{"" + value.total}</Cell>)}
                        <Cell title="总和">{rankList.length > 0 && rankList[1].reduce((a, b) => a + b.total, 0)+""}</Cell>
                    </Panel>
                    <Panel title="本月总量">
                        <Cell title="姓名">{"浏览量"}</Cell>
                        {rankList.length > 0 && rankList[2].map((value, index) => <Cell key={index}
                                                                                        title={value.name}>{"" + value.total}</Cell>)}
                        <Cell title="总和">{rankList.length > 0 && rankList[2].reduce((a, b) => a + b.total, 0)+""}</Cell>
                    </Panel>
                    <Panel title="本日总量">
                        <Cell title="姓名">{"浏览量"}</Cell>
                        {rankList.length > 0 && rankList[3].map((value, index) => <Cell key={index}
                                                                                        title={value.name}>{"" + value.total}</Cell>)}
                        <Cell title="总和">{rankList.length > 0 && rankList[3].reduce((a, b) => a + b.total, 0)+""}</Cell>
                    </Panel>
                </Tabs>
            </div>
            <Tab data={"home"}/>
        </div>
    );
};

export default Home;