import Login from '../login';
import Home from '../home';
import Add from '../add';
import Me from '../me';
import QuestionAdd from '../questionAdd';
import QuestionList from '../questionList';
import QuestionAdmin from '../questionAdmin';
import React from 'react';
import {Router, Route, Switch, Redirect} from 'react-router';
import {createHashHistory} from "history";
import Question from "../question";

const history = createHashHistory();

class RouterConfig extends React.Component {
    render() {
        return (
            <Router history={history}>
                <Switch>
                    <Route path='/' exact render={() => (
                        <Redirect to='/home'/>
                    )}/>
                    <Route path='/home' component={Home}/>
                    <Route path='/add' component={Add}/>
                    <Route path='/me' component={Me}/>
                    <Route path='/login' component={Login}/>
                    <Route path='/login' component={Login}/>
                    <Route path='/questionAdd' component={QuestionAdd}/>
                    <Route path='/question/:id' component={Question}/>
                    <Route path='/questionList' component={QuestionList}/>
                    <Route path='/questionAdmin' component={QuestionAdmin}/>
                </Switch>
            </Router>
        )
    }
}

export default RouterConfig;