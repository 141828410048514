import {useEffect, useState} from 'react';
import {Input, Cell, Button, Toast} from 'zarm';
import axios from "axios";
import 'zarm/dist/zarm.css';

const QuestionAdd = () => {
    const [question, setQuestion] = useState("");
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        document.title = "提交问题"
    }, [])

    function submit() {
        setLoading(true);
        if (question === "") {
            Toast.show('问题不能为空');
            setLoading(false);
        } else {
            axios.get("/api/postQuestion?question=" + question).then(function (res) {
                console.log(res);
                if (res.data.code === -1) {
                    Toast.show(res.data.msg);
                } else {
                    Toast.show("提交成功！");
                }
            }).catch(function (error) {
                Toast.show("提交失败："+error);
            }).then(function () {
                setLoading(false);
            });
        }
    }

    return (
        <div style={{marginTop: 100, margin: 50}}>
            <Cell title="问题">
                <Input
                    clearable
                    type="text"
                    rows={3}
                    placeholder="请输入问题"
                    value={question}
                    onChange={(value) => {
                        setQuestion(value);
                    }}
                    onBlur={(value) => console.log(`onBlur: ${value}`)}
                />
            </Cell>
            <Button block loading={loading} theme="primary" onClick={() => submit()}>
                提交
            </Button>
        </div>
    );
};

export default QuestionAdd;