import {useEffect, useState} from 'react';
import {Input, Cell, Button, Toast} from 'zarm';
import axios from "axios";
import 'zarm/dist/zarm.css';
import Footer from "./footer";

const Login = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        document.title = "登录"
    }, [])

    function login() {
        setLoading(true);
        if (username === "" || password === "") {
            Toast.show('用户名或密码不能为空');
            setLoading(false);
        } else {
            axios.post("/api/auth", {
                username,
                password
            }).then(function (res) {
                console.log(res);
                if (res.data.code===-1){
                    Toast.show(res.data.msg);
                }else {
                    window.location.href="/#/home";
                    localStorage.setItem("token",res.headers.authorization);
                }
            }).catch(function (error) {
                console.log(error);
            }).then(function () {
                setLoading(false);
            });
        }
    }

    return (
        <div style={{marginTop: 100, margin: 50}}>
            <Cell title="用户名">
                <Input
                    clearable
                    type="text"
                    placeholder="请输入用户名"
                    value={username}
                    onChange={(value) => {
                        setUsername(value);
                    }}
                    onBlur={(value) => console.log(`onBlur: ${value}`)}
                />
            </Cell>
            <Cell title="密码">
                <Input
                    type="password"
                    placeholder="请输入密码"
                    value={password}
                    onChange={(value) => {
                        // @ts-ignore
                        setPassword(value);
                    }}
                />
            </Cell>
            <Button block loading={loading} theme="primary" onClick={() => login()}>
                登录
            </Button>
            <Footer/>
        </div>
    );
};

export default Login;