import {useEffect} from 'react';
import {Button} from 'zarm';
import 'zarm/dist/zarm.css';
import Tab from "./tab";

const Me = () => {

    useEffect(() => {
        document.title = "个人中心"
    }, [])

    function logout() {
        localStorage.removeItem("token");
        window.location.href = "/#/login";
    }

    function manage() {
        window.location.href = "/#/questionAdmin";
    }

    return (
        <div style={{marginTop: 100, margin: 50}}>
            {localStorage.getItem("role") === "ROLE_ADMIN" &&
            <Button block theme="primary" onClick={() => manage()}>管理问题</Button>}
            <Button block theme="danger" onClick={() => logout()} style={{marginTop: 10}}>退出登录</Button>
            <Tab data="me"/>
        </div>
    );
};

export default Me;