import {useEffect, useState} from 'react';
import {Cell, Button, Toast, Loading, Input, Switch} from 'zarm';
import axios from "axios";
import 'zarm/dist/zarm.css';
import {useParams} from "react-router-dom";

const Question = () => {
    const {id} = useParams()
    const [question, setQuestion] = useState<any>({});
    const [answer, setAnswer] = useState('');
    const [loading, setLoading] = useState(false);
    const [checked, setChecked] = useState(false);
    useEffect(() => {
        document.title = "问题编辑"
        const token = localStorage.getItem("token");
        if (token === "" || token === null) {
            return;
        }
        axios.defaults.headers.common['Authorization'] = token;
        Loading.show();
        axios.get("/api/getQuestion?id=" + id).then(function (res) {
            console.log(res);
            if (res.data.code === -1) {
                Toast.show(res.data.msg);
            } else {
                setQuestion(res.data.data);
                setAnswer(res.data.data.answer);
                setChecked(res.data.data.ok);
            }
        }).catch(function (error) {
            Toast.show("加载失败：" + error);
        }).then(function () {
            Loading.hide();
        });
    }, [id])

    function submit() {
        setLoading(true);
        if (answer === "") {
            Toast.show('问题不能为空');
            setLoading(false);
        } else {
            var newQuestion = {
                answer: '', ok: false,
                id: 0
            };
            newQuestion.answer = answer;
            newQuestion.ok = checked;
            newQuestion.id = question.id;
            axios.post("/api/setQuestion", newQuestion).then(function (res) {
                console.log(res);
                if (res.data.code === -1) {
                    Toast.show(res.data.msg);
                } else {
                    Toast.show("提交成功！");
                }
            }).catch(function (error) {
                Toast.show("提交失败：" + error);
            }).then(function () {
                setLoading(false);
            });
        }
    }

    return (
        <div>
            <Cell title="问题">
                <Input
                    clearable
                    type="text"
                    placeholder="请输入"
                    value={question.question}
                />
            </Cell>
            <Cell title="答案">
                <Input
                    rows={3}
                    type="text"
                    placeholder="请输入"
                    value={answer}
                    onChange={(value => {
                        setAnswer(value)
                    })}
                />
            </Cell>
            <Cell title="展示" description={<Switch checked={checked} onChange={(value => {
                // @ts-ignore
                setChecked(value)
            })}/>}/>
            <Button block loading={loading} theme="primary" onClick={() => submit()}>
                提交
            </Button>
        </div>
    );
};

export default Question;