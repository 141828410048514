import {useEffect, useState} from 'react';
import {Input, Cell, Button, Toast} from 'zarm';
import axios from "axios";
import 'zarm/dist/zarm.css';

const Add = () => {
    const [total, setTotal] = useState(0);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        document.title = "提交视频总浏览量"
    }, [])

    function login() {
        setLoading(true);
        if (total === 0) {
            Toast.show('浏览量不能为空');
            setLoading(false);
        } else {
            axios.get("/api/postData?total=" + total).then(function (res) {
                console.log(res);
                if (res.data.code === -1) {
                    Toast.show(res.data.msg);
                } else {
                    Toast.show("提交成功！");
                }
            }).catch(function (error) {
                Toast.show("提交失败："+error);
            }).then(function () {
                setLoading(false);
            });
        }
    }

    return (
        <div style={{marginTop: 100, margin: 50}}>
            <Cell title="总浏览量">
                <Input
                    clearable
                    type="number"
                    placeholder="请输入总浏览量"
                    value={total}
                    onChange={(value) => {
                        setTotal(value);
                    }}
                    onBlur={(value) => console.log(`onBlur: ${value}`)}
                />
            </Cell>
            <Button block loading={loading} theme="primary" onClick={() => login()}>
                提交
            </Button>
        </div>
    );
};

export default Add;