import React, {useEffect} from 'react';
import {useState} from 'react';
import {Icon, TabBar, Toast} from 'zarm';
import Footer from "./footer";
import axios from "axios";

const TabIcon = Icon.createFromIconfont('//at.alicdn.com/t/font_1340918_lpsswvb7yv.js');

const Tab = (key) => {

    const [activeKey, setActiveKey] = useState(key.data);
    useEffect(() => {
        const token = localStorage.getItem("token");
        if (token === "" || token === null) {
            window.location.href = "/#/login"
        } else {
            axios.defaults.headers.common['Authorization'] = token;
            axios.get("/api/currentUser").then(function (res) {
                if (res.data.code === -1) {
                    Toast.show(res.data.msg);
                } else {
                    console.log(res.data)
                    localStorage.setItem("role",res.data.data.role.role);
                    if (res.data.data.role.role==="ROLE_ADMIN"){
                        console.log("管理员")
                    }
                }
            }).catch(function (error) {
                Toast.show("自动登录失败！：" + error);
                window.location.href = "/#/login"
            });
        }
    }, [])

    function onSwitch(value) {
        window.location.href = "/#/" + value;
    }

    return (
        <div className="tab">
            <Footer/>
            <TabBar activeKey={activeKey} onChange={(value) => {
                onSwitch(value)
                // @ts-ignore
                setActiveKey(value)
            }
            }>
                <TabBar.Item itemKey="home" title="浏览量" icon={<TabIcon type="time"/>}>
                    主页
                </TabBar.Item>
                <TabBar.Item
                    itemKey="questionList"
                    title="问题集"
                    icon={<Icon type="question-round"/>}
                />
                <TabBar.Item
                    itemKey="me"
                    title="我的"
                    icon={<TabIcon type="user"/>}
                />
            </TabBar>
        </div>
    );
}

export default Tab;
