import React from 'react';
import './footer.css'

const Footer = () => {

    return (
        <footer>
            <div className="copyright">
                武汉朗立创科技有限公司
            </div>
        </footer>
    );
};

export default Footer;